import { render, staticRenderFns } from "./AdminTable.vue?vue&type=template&id=bc348db6&"
import script from "./AdminTable.vue?vue&type=script&lang=ts&"
export * from "./AdminTable.vue?vue&type=script&lang=ts&"
import style0 from "./AdminTable.vue?vue&type=style&index=0&id=bc348db6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QTable,QTd,QSelect,QPopupEdit,QInput,QChip,QToggle,QPagination,QBtn,QIcon});
