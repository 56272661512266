
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import { PaginationFields } from "@/graphql/admin-fragments.ts";
import gql from "graphql-tag";

interface TableColumn {
  [key: string]: any;
  enum?: Array<any>;
  editable?: boolean;
  type: "number" | "string" | "boolean" | "json";
}

@Component({
  components: {
    CircleLoading
  }
})
export default class AdminSteamBot extends Vue {
  @Prop({}) title: any;
  @Prop({ required: true }) modelName: any;
  @Prop({ required: true }) columns: TableColumn[] | any;
  JSON = JSON;

  pagination = {
    rowsPerPage: 1,
    page: 1,
    rowsNumber: 1,
    sortBy: null,
    descending: false
  };

  loading = false;
  selected: any[] = [];
  docs: any[] = [];
  searchConditionText: any = "";
  PaginationFields = PaginationFields;
  pageHeight = 0;

  @Watch("selected")
  selectedWatcher(value) {
    this.$emit("update:selected", value);
  }

  styleFn(offset, height) {
    this.pageHeight = height - offset;

    return {
      height: height - offset + "px",
      "overflow-x": "hidden"
    };
  }

  deselectAll() {
    this.selected = [];
  }

  async updateMutate({ variables = {} }) {
    this.loading = true;
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation updateModelDoc(
            $_id: ID!
            $updatedFields: JSON!
            $unsetFields: JSON
            $modelName: String!
          ) {
            updateModelDoc(
              _id: $_id
              updatedFields: $updatedFields
              modelName: $modelName
              unsetFields: $unsetFields
            )
          }
        `,
        variables: { ...variables }
      });
    } catch (e) {
      this.$q.notify({
        message: e.message,
        multiLine: true,
        type: "negative"
      });
    }
    this.loading = false;
  }

  search(condition) {
    this.searchConditionText = condition ? JSON.stringify(condition) : "";
  }

  get searchCondition() {
    if (this.searchConditionText.indexOf("{") === 0) {
      let condition;
      try {
        eval(`condition=${this.searchConditionText}`);
      } catch {}
      return condition ? condition : {};
    }
    return this.searchConditionText ? { _id: this.searchConditionText } : {};
  }

  get pagesNumber() {
    return Math.ceil(this.pagination.rowsNumber / this.pagination.rowsPerPage);
  }

  get opts() {
    return this.pagination.sortBy
      ? {
          sort: {
            [this.pagination.sortBy]: this.pagination.descending ? -1 : 1
          }
        }
      : {};
  }

  onQueryResult({ data }) {
    if (data && data.getModelDocs) {
      if (data.getModelDocs.page > data.getModelDocs.totalPages) {
        this.pagination.page = data.getModelDocs.totalPages;
      }

      this.pagination.rowsPerPage = data.getModelDocs.limit;

      this.pagination.rowsNumber = data.getModelDocs.totalDocs;
      this.docs = data.getModelDocs.docs.slice();
    }
  }

  refetchQuery() {
    this.$refs.query["$apollo"].queries.query.refetch();
  }
}
